<template>
  <monitoring-items
    :activeModules="activeModules"
    :mapItems="mapEuropeanUnionDecisionsAndOthersItems"
    :api="{
      moduleId: 'pk',
      modulesWithName: 'custom_query_european_union_acts_with_names',
      modules: 'module_european_union_decisions_and_others_header',
      favoriteHated: 'favorite_and_hated_european_union_decisions_and_others_header',
      favoriteHatedResponse: 'favorite_and_hateful_european_union_decisions_and_others_list',
      bookmarks: 'bookmarks/fetchEuropeanUnionDecisionsAndOthers',
    }"
    #default="{items}"
  >
    <european-union-decisions-and-others-list :items="items" />
  </monitoring-items>
</template>

<script>
import MonitoringItems from '../components/MonitoringItems';
import EuropeanUnionDecisionsAndOthersList from '../../EuropeanUnionDecisionsAndOthers/EuropeanUnionDecisionsAndOthersList';
import { mapEuropeanUnionDecisionsAndOthersItems } from '../../EuropeanUnionDecisionsAndOthers/mapEuropeanUnionDecisionsAndOthersItems';

export default {
  props: {
    activeModules: Array,
  },
  components: {
    MonitoringItems,
    EuropeanUnionDecisionsAndOthersList,
  },
  metaInfo() {
    return {
      title: 'Monitoring decyzji i innych aktów UE',
    };
  },
  data() {
    return {
      mapEuropeanUnionDecisionsAndOthersItems: mapEuropeanUnionDecisionsAndOthersItems,
    };
  },
};
</script>
